.checkbox-1 {
  padding-left: 33.3%;
}

.checkbox-2 {
  padding-left: 16.6%;
}

.checkbox-3 {
  padding-left: 50%;
}

.list {
  padding-left: 28px;
  white-space: nowrap;
  color: #636464;
}

.list-1 {
  padding-left: 27px;
  color: #636464;
  min-width: 200px
}

.error-input {
  border: 1px solid red;
}

.error-text {
  color: red;
  font-size: 0.85em;
  margin-top: 4px;
}


.typoAlign {
  display: flex;
  flex-wrap: nowrap;
  padding-left: 28px;
  width: 315px;
  color: #636464;
}

.totalAlign {
  padding-left: 65px;
  white-space: nowrap;
  color: #636464;
}

.spouseTypo {
  padding-left: 4px;
  white-space: nowrap;
  color: #636464;
  min-width: 200px
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 4px;
  /* Adds space below input */
  display: block;
  /* Ensures it appears on its own line */
}


.iconFix {
  position: absolute !important;
  left: 280px;
  padding: 0 10px !important;
  margin-bottom: 10px !important;
}

.css-iv7vbz-MuiGrid-root {
  -webkit-flex-basis: 25%;
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
  max-width: 16.7% !important;
}

.form-container {
  margin: 20px;
}

label {
  color: #636464;
}

.container {
  margin-top: 0px !important;
}

input::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

.checkboxDesign {
  margin-right: 10px;
  margin-bottom: 8px !important;
  width: 8px !important;
  height: 8px !important;
  box-shadow: 3px 3px 1px gray;
  border-radius: 0;
  background-color: transparent;
  padding: 5px !important;
  appearance: none;
  border: 1px solid #BFC0C2;
  -webkit-appearance: none;
}

.checkboxDesign:hover {
  background-color: transparent;
}

.checkboxDesign:checked {
  background-color: gray;
}

.checkboxDesign:checked::before {
  content: '✔';
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 12px;
  width: 100%;
  height: 100%;
}


.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  /* Increased bottom margin for more space between rows */
}

.form-group label {
  width: 100px;
  font-weight: bold;
  margin-right: 20px;
  /* Increased space between the label and the first input field */
}

.form-group input {
  margin-right: 30px;
  /* Increased space between input fields */
  padding: 5px;
  width: 150px;
  border: 1px solid #ccc;
}

.form-group input[type="date"] {
  width: 180px;
}

.form-group input[placeholder="Address"] {
  width: 250px;
}

.form-group input[placeholder="City"] {
  width: 150px;
}

.form-group input[placeholder="State"] {
  width: 60px;
}

.form-group input[placeholder="ZIP"] {
  width: 80px;
}

.intake-button {
  padding: 3px 12px;
  background-color: #0A406D;
  color: white;
  border: none;
  cursor: pointer;
  border: 2px solid #dddddd;
}

.gridHeight {
  height: 27px !important;
}

.addressWidth {
  width: 500px;
}

.grid-container {
  display: flex;
  gap: 10px;
  /* Adjust the gap between items inside the container */
  /* margin-bottom: 10px; */
}

.grid-item {
  flex: 1;
  width: 150px;
  /* Allow grid items to be flexible */
  padding: 8px;
  /* Adjust padding inside grid items */
}

.address-input {
  flex: 2;
  /* Increase the size of the Address field */
  min-width: 385px;
  /* Set a minimum width for the Address field */
}

.itemCenter {
  text-align: center;
}

.checkItem {
  position: relative;
  left: 264px;
  display: flex;
}

.newClass {
  padding: 0 !important;
}

.no-hover:hover {
  background-color: transparent !important;
  /* Prevent background color change */
  box-shadow: none !important;
  /* Remove any shadow on hover */
}

.extrafields {
  width: 84px !important;
}

.checkboxItemExtra {
  left: 40px;
  position: relative;
}

.checkbox-item {
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #8D9094;
  font-weight: 500;
  margin-bottom: 0 !important;
  position: relative;
}

.checkboxItem {
  position: relative;
  left: 78px;
  display: flex;
}

.checkboxItem-1 {
  position: relative;
  left: 78px;
  display: flex;
}

.checkItem-2 {
  position: relative;
  left: 265px;
  display: flex;
}

.back-buttonintake {
  padding: 3px 12px !important;
  background-color: #0A406D;
  color: white;
  border: none;
  cursor: pointer !important;
  height: 25px;
  margin-top: 20px;
  font-size: 13px !important;
}

.boxCheckitem {
  margin-right: 2;
  box-shadow: '3px 3px 1px gray';
  border: 'none';
  border-radius: 0;
  width: 16;
  height: 16;
  color: gray;
  background-color: 'transparent';
}

/* Base styles for inputs */
input {
  width: 200px;
  padding: 8px;
  box-sizing: border-box;
  border: 2px solid #BFC0C2;
  border-radius: 4px;
  font-size: 14px;
  height: 25px;
  /* Set a fixed height for all inputs */
  line-height: 1.5;
  /* Optional: Adjust line-height if necessary */
  border-radius: 0;
}

/* Ensure consistent font weight and padding for date input */
input[type="date"] {
  font-weight: 400;
  padding: 8px;
  height: px;
  line-height: 1.5;
}


/* Style for labels */
label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input[type="date"] {
  font-weight: 400;
  color: #636464;
}

/* Responsive layout for grid container */

/* In your FormDesign.css */
.toast-success {
  background-color: #4caf50 !important;
  color: white !important;
}

.toast-error {
  background-color: #f44336 !important;
  color: white !important;
}

.toast-body {
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}

.toast-progress {
  background: white !important;
}

.houseCss {
  width: 545px !important;
  justify-content: space-between;
}

.historyCss {
  width: 717px !important;
  justify-content: space-between;
}

.emptyInput {
  width: 150px;
}

.headerSpace {
  display: flex;
  justify-content: space-between;
  height: 27px;
}

.responsivessFields {
  display: flex;
}



/* Responsive rules */
/* @media only screen and (max-width: 600px) {
  .grid-item {
    width: 100%;
  }

  input {
    font-size: 14px;
  }
} */

.labelcheckbox {
  margin-top: 3px !important;
  font-size: 16px;
  margin-left: 25px;
  white-space: nowrap;
}

.form-container {
  width: 100%;
  padding: 20px;
}

.form-header {
  text-align: center;
  color: #24527B;
  letter-spacing: 2px;
  margin-bottom: 20px;
}

.form-section {
  /* margin-bottom: 20px; Add space between sections */
  display: grid;
  grid-template-columns: 0.8fr 3fr;
  /* Label on one side, input fields on the other */
  align-items: start;
}

.form-section label {
  font-weight: bold;
  margin-right: 20px;
  /* Add space between the label and input fields */
  margin-left: 25px;
  margin-top: 6px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.grid-container {
  display: flex;
  grid-template-columns: repeat(3, 1fr);
  /* 3 columns for the input fields */
  gap: 20px;
}

.grid-container input {
  width: 150px;
  padding: 8px;
  box-sizing: border-box;
  text-transform: capitalize;
}

.wide-input {
  grid-column: span 3;
  /* Make the address input span across all columns */
}

.error {
  color: red;
  font-size: 0.85rem;
  margin-top: 5px;
}

button {
  background-color: #24527B;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  margin-top: 20px;
}

.custom-datepicker {
  padding: 8px;
  font-size: 15px;
  width: 150px;
  cursor: pointer;
}

.custom-datepicker:focus {
  border-color: #007BFF;
  /* Highlight border color on focus */
  outline: none;
  /* Remove outline */
}

.react-datepicker-popper {
  z-index: 9999;
}

.react-datepicker__navigation--previous {
  margin-top: 0 !important;
}

.react-datepicker__navigation--next {
  margin-top: 0 !important;
}

@media only screen and (max-width: 1440px) {
  .grid-item {
    width: 100%;
  }

  input {
    font-size: 14px;
    width: 150px;
  }

  .checkItem {
    position: relative;
    left: 226px;
    display: flex;
  }

  .checkboxItem {
    position: relative;
    left: 40px;
    display: flex;
  }

  .checkboxItem-1 {
    position: relative;
    left: 40px;
    display: flex;
  }

  .houseCss {
    width: 528px !important;
    justify-content: space-between;
  }

  .historyCss {
    width: 701px !important;
    justify-content: space-between;
  }

  .checkItem-2 {
    left: 226px;
  }

  .address-input {
    min-width: 280px;
  }

  .familyDataContainer {
    padding: 20px !important;
    width: fit-content;
  }

  .form-container {
    padding: 0 !important;
  }

  .emptyInput {
    width: 150px;
  }

  .typoAlign {
    width: 307px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .grid-item {
    width: 50%;
  }

  .grid-container {
    flex-wrap: inherit;
  }

  .list-1 {
    padding-left: 28px !important
  }

  .list {
    padding-left: 28px !important;
  }

  .familyDataContainer {
    padding: 40px !important;
    width: fit-content !important;
  }

  /* .list-1{
    padding-left: 0 !important;
    padding-top: 0 !important;
  } */

  .css-1xdgku8-MuiGrid-root>.MuiGrid-item {
    padding-top: 0 !important;
  }

  .gridHeight {
    height: 100%;
  }

  .address-input {
    min-width: auto
  }

  .checkItem {
    left: 184px;
  }

  .checkItem-2 {
    left: 179px;
  }

  .checkboxItem {
    position: relative;
    left: 18px;
    display: flex;
  }

  .checkboxItem-1 {
    left: 18px;
  }

  .responsiveWidth {
    min-width: 0 !important;
    flex-basis: 16.7% !important;
  }

  .houseCss {
    width: 501px !important;
    justify-content: space-between;
  }

  .historyCss {
    width: 662px !important;
    justify-content: space-between;
  }

  .typoAlign {
    padding-left: 28px !important;
  }
}

@media only screen and (min-width: 767px) and (max-width: 1023px) {
  .grid-item {
    width: 50%;
  }

  .grid-container {
    flex-wrap: inherit;
    gap: 0;
  }

  .locResp {
    margin-right: 9px !important;
  }

  .display {
    height: 70px;
  }

  .typoAlign {
    width: 222px;
  }

  .houseCss {
    width: 501px !important;
    justify-content: space-between;
  }

  .historyCss {
    width: 662px !important;
    justify-content: space-between;
  }

  .familyDataContainer {
    padding: 15px !important;
  }

  .list-1 {
    padding-left: 28px;
  }

  .css-1xdgku8-MuiGrid-root>.MuiGrid-item {
    padding-top: 0 !important;
  }

  .address-input {
    min-width: auto
  }
}


.container {
  display: flex;
  align-items: flex-start;
  /* Align items at the start */
}

.add-button {
  margin-right: 20px;
  /* Space between button and list */
  padding: 10px 15px;
  font-size: 24px;
  cursor: pointer;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.list-item {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  text-align: left;
}

.contentPlus {
  display: flex;
  align-items: center;
  white-space: nowrap;
  min-width: 275px;
}