/* src/components/Login/LoginPage.css */
.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    /* background-color: red; */
    background-color: #101846;
  }
  
  .login-container {
    border: 1px solid #fff;
    background: #fff;
    padding: 30px;
    border-radius: 10px;
    max-width: 340px !important;
    margin: auto;
  }
  