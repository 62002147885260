.circles{
    border: 1px solid #d4d4d4;
    width: 23px;
    height: 23px;
    border-radius: 100%;
    position: relative;
    margin: 4px;
    background-color: #fff;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
  }
  .circles.plus:before,
  .circles.plus:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      /* background:rgb(255, 255, 255); */
      background-color: #DDDDDD;
  }
  .circles.plus:before{
      width: 5px;
      margin: 2px auto;
  }
  .circles.plus:after{
      margin: auto 2px;
      height: 5px;
  }