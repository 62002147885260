.containers {
  position: relative;
  margin-top: 50px !important;
  width: 100%;
  display: flex;
  justify-content: flex-start;
 }
 
 
 .text-with-underline {
  position: relative;
  display: inline-block;
  padding-bottom: 0px !important;
  width: 100%;
  text-align: left;
  margin-bottom: 0;
  font-weight: bold;
 }
 
 
 .text-with-underline::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: black;
  z-index: -1;
 }
 
 
 .debt-name{
  text-align: center;
  letter-spacing: 5px;
  color: #0A406D;
  font-weight: 500;
 }
 
 
 .main-containe{
  text-align: left;
 }
 
 
 .checkbox-container {
  display: flex;
  gap: 25px;
  flex-wrap: wrap;
  margin-bottom: 10px;
 }
 
 
 .checkbox-item {
  font-size: 14px;
  display: flex;
  align-items: center;
 
 
  color: #8D9094;
  font-weight: 500;
 
 
 }
 
 .input-field {
  margin-left: 5px;
  border: 2px solid #dddddd;
  padding: 8px;
  border-radius: 0px;
  text-transform: capitalize;
 }
 
 .input-containers {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-start;
 }
 .coverage-button-container{
  display: flex;
  justify-content: space-between;
 }
 
 
 .submit-button{
  padding: 3px 12px;
  background-color: #0A406D;
  color: white;
  border: none;
  cursor: pointer;
  height: 25px;
  margin-top: 0;
 }
 .button-div{
  margin: 0 0 0 auto;
 }
 
 .back-button{
  padding: 3px 12px !important;
    background-color: #0A406D;
    color: white;
    border: none;
    cursor: pointer !important;
    height: 25px;
    margin-top: 0;
    font-size: 13px !important;
 }
 
 
 
 
 .input-field::placeholder {
  color: #b0b0b0;
 }
 
 
 .sub-text{
  display: flex;
  gap: 10px;
 }
 
 
 .input-field-5{
  border: 2px solid #dddddd;
  padding: 8px;
  width: 150px;
 }
 
 
 .input-field-sub-1{
  /* margin-left: 5px; */
  border: 2px solid #dddddd;
  padding: 8px;
  width:300px;
  text-transform: capitalize;
 }
 
 .input-field-sub-2{
  /* margin-left: 5px; */
  border: 2px solid #dddddd;
  padding: 8px;
  width:200px;
 
 
 }
 
 .input-field-subFinal-1 {
  padding: 10px;
  box-sizing: border-box;
  width: 41%;
  height: 26px;
  border: 2px solid #dddddd;
 }
 .hr-tag{
 margin-bottom: 20px;
 margin-top: 0 !important;
 }


 .css-3eghsz-PrivatePickersYear-button.Mui-selected {
  color: #fff;
  background-color: #1976d2;
  padding: 6px !important;
}

.css-1nc86f8-MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-input {
  padding: 1px 10px 3px !important;
  cursor: pointer;
}

 @media (min-width: 768px) and (max-width: 1023px) {
 .input-field-sub-1{
  width: 200px;
 }
 .input-field-5{
  width: 200px;
 }
 .debt-name{
  font-size: 32px;
 }
 .input-container{
  display: grid !important;
  gap: 5px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  justify-content: flex-start;
 }
 .submit-button{
  margin-left: 5px;
 }
 }

 @media only screen and (max-width: 1440px) {
  .coveragePadding{
    padding: 40px !important;
  }
  
  .input-field-sub-1{
    width: 200px !important;
  }

  .input-field-5{
    width: 80px !important;
  }
 }
 

@media (min-width: 1024px) and (max-width: 1320px) {
  .checkbox-lists {
    gap: 30px !important; 
  }

  .checkbox-item {
    /* flex-direction: column; */
    align-items: flex-start;
  }

  .input-container {
    /* flex-wrap: wrap; */
    gap: 10px; 
    justify-content: center; 
  }

  .input-field {
    width: 100% !important; 
    margin-bottom: 10px; 
  }

  .lenderFields {
    width: 100% !important;
  }

  .add-button {
    width: 100%;
    text-align: center;
    font-size: 14px;
    padding: 10px 0;
  }

  .main-container {
    padding: 20px;
  }

  .debt-name {
    font-size: 24px;
  }
}
