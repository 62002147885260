.priorityAlign{
    position: relative;
    right: 250px;
}

@media only screen and (min-width: 424px) and (max-width: 768px) {
    .priorityAlign{
        position: relative;
        right: 137px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    .priorityAlign{
        position: relative;
        right: 200px !important;
    }
  }

  @media only screen and (max-width: 1440px) {
    .priorityAlign{
        position: relative;
        right: 202px;
    }
  }
  