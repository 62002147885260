.addcircles{
    border: 1px solid #d4d4d4;
    width: 23px;
    height: 23px;
    border-radius: 100%;
    position: relative;
    margin: 4px;
    background-color: #fff;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
  }
  .addcircles.plus:before,
  .addcircles.plus:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      /* background:rgb(255, 255, 255); */
      background-color: #DDDDDD;
  }
  .addcircles.plus:before{
      width: 5px;
      margin: 2px auto;
  }
  .addcircles.plus:after{
      margin: auto 2px;
      height: 5px;
  }


  .subtractcircles{
    border: 1px solid #d4d4d4;
    width: 23px;
    height: 23px;
    border-radius: 100%;
    position: relative;
    margin: 4px;
    background-color: #fff;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
  }
  .subtractcircles.plus:before,
  .subtractcircles.plus:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      /* background:rgb(255, 255, 255); */
      background-color: #DDDDDD;
  }
  .subtractcircles.plus:before{
    width: 0px;
}
  .subtractcircles.plus:after{
      margin: auto 2px;
      height: 5px;
  }


  .iconDesign{
    display: flex;
    position: fixed;
    right: 375px;
    /* top:29px; */
    z-index: 1
  }



 @media only screen and (min-width: 768px) and (max-width: 1024px) {
  .iconDesign{
    right: 160px;
  }
}

.graphOverflow{
  margin-top: 100px;
}

.zoomGrpah{
  height: 100vh;
  display : flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

/* @media only screen and (max-width: 1440px) {
    .iconDesign{
    right: 290px;
  }
} */

