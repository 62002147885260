.text-with-underline .date {
  flex: 1;
  text-align: left;
  font-weight: 600;
}

.text-with-underline .review-items {
  flex: 1;
  text-align: left;
  padding-left: 20px;
  font-weight: 600;
  letter-spacing: 2px;
}



.container {
position: relative;
margin-top: 50px;
width: 100%;
display: flex;
justify-content: flex-start;
}

.text-with-underline {
position: relative;
display: flex;
justify-content: space-between;
align-items: center;
padding-bottom: 5px;
width: 100%;
}

.text-with-underline::after {
  content: "";
  position: absolute;
  left: 28px;
  bottom: 0;
  width: 97%;
  height: 2px;
  background-color: #bcbcbc;
  z-index: -1;
}

.text-with-underline span {
display: inline-block;
}

.text-with-underline .question {
flex: 1;
text-align: left;
}

.text-with-underline .response {
flex: 1;
text-align: right;
}

.text-with-underline input[type="checkbox"] {
margin-right: 10px; 
}

.text-with-underline input[type="text"] {
width: 200px; 
}

 .review-form-container {
  position: relative;
  width: 100%;
}

.form-item {
  margin-bottom: 10px;
} 
.line
 .line-with-text {
  position: relative;
  display: flex;
  justify-content: space-between; 
  align-items: center;
  width: 100%;
  padding: 0;
  margin: 0;
}

 
.line-with-text span {
  display: inline-block;
}

 .line-with-text .left-text {
  flex: 1;
  text-align: left;
  margin-left: 30px;
  color: #818488;
  font-size: 12px;
}
 
 .line-with-text .right-inputs {
  flex: 1;
  text-align: left;
} 

.line-with-text input[type="checkbox"] {
  margin-right: 10px; 
}

.line-with-text input[type="text"] {
  width: 94%;
  height: 25px;
  border: 2px solid #ccc;
}

.main-new-container {
  position: relative;

  width: 100%;
}



.btn{
  padding: 3px 24px;
  background-color: #0A406D ;
  color: white ;
  border: none;
  cursor: pointer;
  margin-top: 25px;
}

/*                   




                     /* General responsive adjustments */
* {
box-sizing: border-box;
}

body {
margin: 0;
padding: 0;
font-family: Arial, sans-serif;
}

/* Mobile-first styles */
.main-container {
text-align: left;
padding: 10px;
}

.review-name {
text-align: center;
letter-spacing: 5px;
color: #0A406D;
font-size: 15px; 
font-family: fangsong;
font-weight: 500;
font-size: 31px;
}

.container {
display: flex;
justify-content: flex-start;
margin-top: 30px;
}

.text-with-underline {
display: flex;
justify-content: space-between;
align-items: center;
padding-bottom: 5px;
position: relative;
}

.text-with-underline::after {
content: "";
position: absolute;
left: 10px;
bottom: 0;
width: 95%;
height: 1px;
background-color: black;
}

.line-with-text {
display: flex;
justify-content: space-between;
align-items: center;
padding: 5px 0;
}



.line-with-text .right-inputs {
flex: 3;
display: flex;
/* justify-content: space-between; */
}

.line-with-text input[type="text"] {
width: 100%;
height: 25px;
padding: 5px;
}

.full-width-container {
width: 97.2%;
padding: 10px;
margin-left: 20px;
}

.full-width-input {
width: 97.36%;
height: 80px;
padding: 10px;
border: 2px solid #ccc;
font-size: 14px;
resize: none;
margin-left: 20px;
}

.btn{
padding: 3px 12px;
background-color: #0A406D ;
color: white ;
border: none;
cursor: pointer;
margin-top: 25px;
margin-right: 70px;
float: right;
}


 .circle{
  border: 1px solid #d4d4d4;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  position: relative;
  margin: 4px;
  background-color: #c9c9c9;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
.circle.plus:before,
.circle.plus:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background:rgb(255, 255, 255);
}
.circle.plus:before{
    width: 2px;
    margin: 2px auto;
}
.circle.plus:after{
    margin: auto 2px;
    height: 2px;
} 


 .line-with-text .left-text {
  flex: 1;
  text-align: left;
  margin-left: 30px;
  color: #818488;
  font-size: 12px;
  font-weight: 500;
  margin-right: 10px;

}

 
 .line-with-text .right-inputs {
  flex: 1;
  text-align: left;
} 

 .form-item {
  margin-bottom: 10px;
} 

 .line-with-text {
  position: relative;
  display: flex;
  justify-content: space-between; 
  align-items: center;
  width: 100%;
  padding: 0;
  margin: 0;
}


/* Media query for tablets (min-width: 600px) */
@media only screen and (min-width: 767px) and (max-width: 1024px) {

.review-name {
  font-size: 24px;
}

.line-with-text input[type="text"] {
  width: 90%;
}

.btn {
  padding: 3px 12px;
  background-color: #0A406D ;
  color: white ;
  border: none;
  cursor: pointer;
  margin-top: 25px;
  float: right;
  margin-right: 0px;
}
.full-width-input {
  height: 120px;
  font-size: 16px;
  width:100%;
  margin-left: 20px;
}
}

/* Media query for large screens (min-width: 1024px) */
@media (min-width: 1024px) and (max-width:1439px) {
.review-name {
  font-size: 28px;
  margin-right: 20px;
}

.container {
  margin-top: 50px;
}

.line-with-text .left-text {
  font-size: 14px;
}

.line-with-text input[type="text"] {
  width: 87%;
}

.full-width-input {
  height: 120px;
  font-size: 16px;
  width:99%;
  margin-left: 20px;
}

.btn {
  padding: 3px 12px;
  background-color: #0A406D ;
  color: white ;
  border: none;
  cursor: pointer;
  margin-top: 25px;
  float: right;
  margin-right: 16px ;
}
}

@media (min-width: 1440px) and (max-width: 1550px){

.full-width-input {
  height: 120px;
  font-size: 16px;
  width:97.9%;
  margin-left: 20px;
}
.btn {
  padding: 3px 12px;
  background-color: #0A406D ;
  color: white ;
  border: none;
  cursor: pointer;
  margin-top: 25px;
  float: right;
  margin-right: 43px ;
}
}
.full-width-container {
  width: 97.2%;
  padding: 10px;
  margin-left: 0;
}


.line-with-text input[type="text"] {
  width: 87%;
  height: 25px;
  padding: 5px;
}
.customs-checkbox {
  margin-right: 8px;
  width: 11px;
  height: 11px;
  box-shadow: 3px 3px 1px gray;
  border-radius: 0;
  background-color: transparent;
  padding: 0;
  appearance: none; 
  -webkit-appearance: none; 
}

.customs-checkbox:hover {
  background-color: transparent; 
}
.customs-checkbox:checked {
  background-color: gray;
  }
  
  .customs-checkbox:checked::before {
  content: '✔';
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 12px;
  width: 100%;
  height: 100%;
  }
  
 
