.priorStyle{
    color: #0A406D;
    letter-spacing: 2px !important;
    font-weight: bold !important;
    text-align: left;
    padding: 0 24px;
}

.nav-list .MuiListItem-root {
    padding: 8px 16px;
  }

  .checkboxDesignRight {
    margin-right: 10px;
    margin-bottom: 1px !important;
    width: 8px !important;
    height: 8px !important;
    box-shadow: 3px 3px 1px gray;
    border-radius: 0;
    background-color: transparent;
    padding: 5px !important;
    appearance: none; 
    border: 1px solid #BFC0C2;
    -webkit-appearance: none; 
  }
  
  .checkboxDesignRight:hover {
    background-color: transparent; 
  }
  .checkboxDesignRight:checked {
    background-color: gray;
    }
    
    .checkboxDesignRight:checked::before {
    content: '✔';
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 12px;
    width: 100%;
    height: 100%;
    }


 /* Override the font size for the generated MUI Typography class */
.css-et1ao3-MuiTypography-root {
  font-size: 14px !important; /* Use !important if necessary to ensure it takes precedence */
}
