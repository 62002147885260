.container {
  position: relative;
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
 }
 
 
 .text-with-underline {
  position: relative;
  display: inline-block;
  padding-bottom: 5px;
  width: 100%;
  text-align: left;
 }
 
 
 .text-with-underline::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: black;
  z-index: -1;
 }
 
 
 .debt-name {
  text-align: center;
  letter-spacing: 5px;
  color: #24527B;
  font-weight: 500;
  font-family: 'fangsong';
 }
 
 
 .main-containe {
  text-align: left;
 }
 
 
 
 
 .checkbox-item {
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #8D9094;
  font-weight: 500;
 }
 
 
 .checkbox-item input {
  margin-right: 10px;
  margin-bottom: 5px;
 }
 
 
 .input-field {
  margin-left: 5px;
  border: 2px solid #dddddd;
  padding: 8px;
  width: 145px !important;
 }
 
 
 .input-container {
  display: flex;
  gap: 10px;
  justify-content: space-between !important;
 }

 .lenderFields{
    width: 300px !important;
 }
 
 
 .input-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
 }

 .add-button {
  padding: 3px 12px !important;
  background-color: #0A406D;
  color: white;
  border: none;
  cursor: pointer !important;
  height: 25px;
  margin-top: 0;
  font-size: 13px !important;
 }
 
 .back-button-debts{
  padding: 3px 12px !important;
  background-color: #0A406D;
  color: white;
  border: none;
  cursor: pointer !important;
  height: 25px;
  margin-top: 0;
  font-size: 13px !important;
  margin-bottom: 35px;
 }
 
 
 
 .input-field::placeholder {
  color: #b0b0b0;
 }
 
 
 .error-message {
  color: red;
  font-size: 12px;
 }
 .App {
  text-align: left;
 }
 
 
 
 input::-webkit-outer-spin-button,
 input::-webkit-inner-spin-button {
 -webkit-appearance: none;
 margin: 0;
 }
 
 
 input[type="number"] {
 -moz-appearance: textfield;
 }
 
 .checkbox-list {
 display: flex;
 flex-wrap: wrap;
 margin-bottom: 10px;
 gap:108px ;
 }
 .checkbox-lists{
  display: flex;
 flex-wrap: wrap;
 margin-bottom: 10px;
 gap:108px;
 }
 .checkbox-item {
 display: flex;
 align-items: center;
 margin-bottom: 8px;
 }
 
 
.custom-checkbox {
  margin-right: 8px;
  width: 11px;
  height: 11px;
  box-shadow: 3px 3px 1px gray;
  border-radius: 0;
  background-color: transparent;
  padding: 0;
  appearance: none; 
  -webkit-appearance: none; 
}

.custom-checkbox:hover {
  background-color: transparent; 
}
.custom-checkbox:checked {
  background-color: gray;
  }
  
  .custom-checkbox:checked::before {
  content: '✔';
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 12px;
  width: 100%;
  height: 100%;
  }
 
 
 .input-field {
    margin-left: 8px;
    padding: 4px;
    border: 1px solid gray;
    border-radius: 4px;
    text-transform: capitalize !important;
    }
 
 

 @media only screen and (max-width: 1440px) {
  .checkbox-lists, .checkbox-list{
    gap: 0px !important;
  }

    .debtPadding{
      padding: 40px !important;
    }
    .css-nze9p8 {
      -webkit-box-flex: 1;
      -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      padding-top: 40px;
      padding-bottom: 40px;
      padding-left: 30px !important;
    
  }
  .checkbox-lists, .checkbox-list {
    gap: 52.3px !important;
  }

  .debtPadding {
    padding: 40px !important;
  }

   .checkbox-lists, .checkbox-list {
    gap: 0px !important;
  }

  .debtPadding {
    padding: 40px !important;
  }

  .css-nze9p8 {
    flex-grow: 1;
    padding: 40px 30px !important; 
  }
}
 

 @media (min-width: 1024px) and (max-width: 1790px) {
  .checkbox-lists{
    gap: 54.3px !important;
  }

  .checkbox-list{
    gap: 54.3px !important;
  }
  .MuiDrawer-paper{
    height: 100% !important;
  }

  .debtPadding {
    padding: 40px !important;
  }

}



/* @media only screen and (max-width: 1399px) and (min-width: 1024px) {
  .checkbox-lists, .checkbox-list {
    gap: 30px !important; 
  }

  .debtPadding {
    padding: 30px !important; 
  }

  .css-nze9p8 {
    padding: 30px 20px !important; 
  }
} */

@media only screen and (max-width: 1023px) and (min-width: 768px) {
  .checkbox-lists {
    gap: 20px !important; 
  }
.debt-add-button{
  display: flex;
  justify-content: end;

}
  
} 



