.drawer-paper {
  width: 300px;
  box-sizing: border-box;
  background-color: #DDDDDD !important;  
  position:fixed;

}

.logo-section {
  padding: 16px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.search-box {
  padding: 16px;
}

.delete-button {
  padding-left: 32px;
}

.bottom-section {
  padding: 16px;
  background-color: #BFC0C2;
  text-align: center;
  cursor: pointer;
}

.leftCheckbox {
  margin-right: 8px;
  width: 11px;
  margin-bottom: 8px;
  height: 11px;
  box-shadow: 3px 3px 1px gray;
  border-radius: 0;
  background-color: transparent;
  padding: 0;
  appearance: none; 
  border: 1px solid #BFC0C2;
  -webkit-appearance: none; 
}

.leftCheckbox:hover {
  background-color: transparent; 
}
.leftCheckbox:checked {
  background-color: gray;
  }
  
  .leftCheckbox:checked::before {
  content: '✔';
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 12px;
  width: 100%;
  height: 100%;
  }


.row{
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 10px 15px;
}

.leftSection{
  display: flex;
  align-items: center;
}


.icon{
  flex: 0 0 auto;
  /* color: #0A406D; */
  color: gray;
  padding-right: 10px;
  font-weight: bold;
}

.text{
  padding-left: 8px;
  color: #0A406D;
  font-weight: bold;
}

.plus{
  color: #0A406D;
  font-weight: bold;
}

.content{
  padding: 10px 15px;
  background: '#fff',
}

.top{
  margin-top: 8em;
}

.list-text{
  color: grey;
  font-weight: normal;
  font-size: 12px;
}

.ping{
  text-align: right;
}

.new-row{
  display: inline-flex;
  width: 100%;
}
.iconsize{
  width: 100%;
  text-align: end;
}

.text-cov{
  font-size: 12px;
  font-weight: 500;
  width: 100%;
  color: #8D9094;
  white-space: nowrap;
  margin-left: 5px;
}

.textAlign{
  /* text-align: center; */
  color: #0A406D;
  padding: 0 40px;
}
.key-style{
color: gray;
font-size: 15px;
text-transform: uppercase;
font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}



