.boldText {
  font-weight: bold
}
/* 
.dashboardCheckbox {
  margin-right: 10px;
  width: 14px;
  height: 14px;
  box-shadow: 3px 3px 1px gray;
  border-radius: 0;
  background-color: transparent;
  padding: 0;
  appearance: none; 
  -webkit-appearance: none; 
}

.dashboardCheckbox:hover {
  background-color: transparent; 
}
.dashboardCheckbox:checked {
  background-color: gray;
  }
  
  .dashboardCheckbox:checked::before {
  content: '✔';
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 12px;
  width: 100%;
  height: 100%;
  }
   */



.arrowIcon{
  padding-right: 0 !important;
  color: gray;
  cursor: pointer;
}


.dashboardCheckbox {
  margin-right: 8px;
  width: 11px;
  height: 11px;
  box-shadow: 3px 3px 1px gray;
  border-radius: 0;
  background-color: transparent;
  padding: 0;
  appearance: none; 
  border: 1px solid #BFC0C2;
  -webkit-appearance: none; 
}

.dashboardCheckbox:hover {
  background-color: transparent; 
}
.dashboardCheckbox:checked {
  background-color: gray;
  }
  
  .dashboardCheckbox:checked::before {
  content: '✔';
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 12px;
  width: 100%;
  height: 100%;
  }
  